<template>
  <div class="wrapper">
    <div v-for="(line,lineInd) in audioLines" :key="lineInd">
      <ContentManageItem
        @insertNewLineAfter="audioLines.splice(lineInd + 1, 0, $event)"
        @unionNext="unionGraph(lineInd)"
        @deleteSelf="audioLines.splice(lineInd,1)"
        :line="line"
        :unionable="lineInd < audioLines.length-1 && audioLines[lineInd+1].paragraphKey===line.paragraphKey"
      />
    </div>
  </div>
</template>

<script>
import ContentManageItem from "./components/ContentManageItem";
export default {
  props: {
    audioLines: {
      required: true
    }
  },
  components: { ContentManageItem },
  methods: {
    unionGraph(lineInd) {
      let thisLine = this.audioLines[lineInd];
      let delLine = this.audioLines[lineInd + 1];
      this.audioLines.splice(lineInd + 1, 1);
      thisLine.text += delLine.text;

      thisLine.unreadFlags = thisLine.unreadFlags.concat(delLine.unreadFlags);
      thisLine.unread = !thisLine.unreadFlags.some(ele => !ele);
      thisLine.audio = null;
    }
  }
};
</script>

<style scoped>
.wrapper {
  padding: 0 0.5em 0.5em;
  border: 1px solid #ddd;
}
</style>